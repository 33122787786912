import {data as a1} from "./articles/our_origin"
import {data as a2} from "./articles/hashcode"

const articles = [
		a1, a2,
	].sort((x, y) => y.date - x.date); // newest articles at front

function translate(articleIn, lang){
	const article = Object.assign({}, articleIn);
	const langInd = (lang.startsWith("de")) ? 1 : 0;
	const translFields = ["title", "subtitle", "content", "preview"];
	for(const field of translFields){
		article[field] = article[field][langInd] || article[field][0]; // english is default if de is not available
	}
	return article;
}

function getArticles(lang){
	return articles.map((a) => translate(a, lang));
}

function getArticleById(id, lang){
	let a = articles.find(a => a.id === id);
	if(a) a = translate(a, lang);
	return a;
}
export {getArticles, getArticleById};