// returns a list
Object.fromEntries = Object.fromEntries || ((arr) => arr.reduce((acc, [k, v]) => {acc[k] = v;return acc}, {}));
const collect = (t, i) =>
		Object.entries(t).reduce((list, [k, v]) => 
			Array.isArray(v) ?
				list.concat([[k, v[i]]])
			: list.concat(collect(v, i).map(([k2, v2]) => [k + "." + k2, v2]))
		, [])
	
// convert nested objects like {a: {b: "abc"}} to {"a.b" : "abc"}
const convert = (t, i) => Object.fromEntries(collect(t, i))

const translation = {
	nav:{
		solutions: 	["Solutions", "IT Lösungen"],
		blog: 			["Blog", "Blog"],
		team: 			["Team", "Team"],
	},
	title:{
		slogan: [	"We build your future.",
						"Wir entwickeln Ihre Zukunft."],
		subslogan: ["Solutions for the world's most demanding problems.",
								"IT Lösungen für die herausfordernsten Probleme."],
	},
	solutions: {
		ai: {
			title: ["AI & Computer Vision", "KI & Bildverarbeitung"],
			content: [ "We apply the latest machine learning and computer vision research results in real world applications. Past projects include Storm Forecast Modelling and a (still unreleased) app that recognizes and matches clothing products.",
			 "Wir nutzen die neusten Forschungsergebnisse aus den Bereichen Machine Learning und Bildverarbeitung in unserer Arbeit. Zu den bisherigen Projekten gehört ein System zur Verbesserung von Wettervorhersagen und eine mobile App zur Erkennung von Kleidungsstücken."]
		},

		webDev:{
			title: ["Web Development", "Webentwicklung"],
			content: [ "We are fluent in latest web and mobile app development languages and UI/UX Design concepts. Completed projects include a news app for Android / IOS, a web-app for machining cost estimations and many more.",
			"Unsere Technologien umfassen alle gängigen Tech-Stacks und Programmiersprachen, sowie professionelle Software im Bereich UI/UX Design. Zu unseren Referenzen gehört etwa eine Nachrichtenapp für Android / IOS oder eine Web-App zur Kostenbestimmung im Bereich industrieller Produktionsverfahren."],
		},

		automation: {
			title: ["Automation", "Automatisierungstechnik"],
			content: ["We develop custom solutions for controlling and administrative use cases as well as task automation. Our past experiences include <b>machine control systems</b> for parcel sorting facilities and <b>dynamic motion planners</b> for bleeding edge robot-based logistics solutions.",
			"Wir entwickeln individuelle Lösungen für Maschinensteuerung und Verwaltung. Unsere bisherigen Projekte umfassen hochfunktionale <b>Leitrechner</b> von Paketverteilungszentren und <b>dynamische Bewegungsplaner</b> für neuartige roboterbasierte Paketsortierung."],
		},
		
		prototype: {
			title: ["Prototype Development", "Prototypenentwicklung"],
			content: ["We are engaged in a variety of innovative non-profit projects spanning the fields of Embedded Software Development, Electrical Engineering and CAD/CAM. Max is the project leader of <b>MeginaSat</b>, a <b>space satellite</b> aiming to establish an optical downlink of 100 MBit/s to Earth.",
			"Wir beschäftigen uns mit vielen non-profit Projekten, die die Themenbereiche Embedded Software Programmierung, Elektotechnik und CAD/CAM abdecken. Max ist der Projektleiter von <b>MeginaSat</b>, einem <b>Kleinsatellit</b> der ab 2021 mit 100 MBit/s optisch Daten auf die Erde übertragen soll."],
		},

		iot: {
			title: ["Internet of Things", "Internet of Things"],
			content: ["We have many years of technical experience in the fields of Internet of Things. Our scope includes <b>sensor node development</b>, management of <b>decentralized networks</b> and applications in the fields of air pollution measurements and storm forecasting.",
			"Wir haben mehrjährige technische Erfahrung im Bereich Internet of Things und beschäftigen uns mit der Entwicklung von denzentraler Sensorik und Netzwerke. Anwendungsgebiete beinhalten die flächendeckende Messung von Feinstaubbelastung und SmartHome-Wetterstationen für bessere Wettervorhersagen."],
		},

		dataProcessing: {
			title: ["Data Processing", "Datenverarbeitung"],
			content: ["Almost all of our fields of activity include data processing. Our most relevant projects are a Storm Forecasting System or an app that recognizes clothing on photos from a dataset of thousands of product images.",
			"Fast alle unserer Tätigkeitsbereiche schließen Datenverarbeitung ein. Zwei besonders relevante Projekte dazu sind ein System zur Verbesserung von Sturmvorhersagen und eine App, die Kleidungsstücke aufgrund einer Produktdatenbank von mehereren Tausend Produkten zuordnet."],
		}
	},
	blogPrev:{
		title: ["What's Up In Tech", "Neues aus der Tech-Welt"],
		subtitle: ["Our Blog about the Newest Trends in Machine Learning and More", 
							"Unser Blog zu den neusten Techniktrends"],
	},
	blog:{
		dateOn: ["On", "Vom"],
		follow: ["Liked the article? Follow us for more:", "Hat dir der Artikel gefallen? Folge uns für mehr:"],
		share: ["Or share this article with your friends:", "Oder teile den Artikel mit deinen Freunden:"]
	},
	contact:{
		contact: ["Contact Us:", "Kontakt aufnehmen:"],
		success: ["Mail was send successfully", "Mail erfolgreich gesendet"],
		msg: ["We will come back to you as soon as possible.", "Wir werden uns umgehen bei Ihnen melden."],
		close: ["Close", "Schließen"],
	},
	footer:{
		imprint: ["Imprint", "Impressum"],
		privacy: ["Privacy Policy", "Datenschutz"],
		showIn: ["Anzeigen in ", "Show in"]
	},
	blogOverview:{
		title: ["Blog", "Blog"],
		subtitle: ["Background Stories & Technology Articles",
							"Blicke hinter die Kulissen & Technik Artikel"],
	}
};

export const translationEN = convert(translation, 0);
export const translationDE = convert(translation, 1);
