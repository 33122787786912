import React from 'react';
import Figure from 'react-bootstrap/Figure';
// first: en, second: de

function getContentEN(){
	return (<>
		<p>
			May 2019. I am all set-up to compete at the Intel International Science and Engineering Fair 2019 in Phoenix (Arizona), the world’s largest science competition for pre-university students of its kind. For the past 2 years, I have been working on a self-initiated project aiming to improve short-term storm forecasting accuracies. It involved Internet of Things, Machine Learning, Electrical Engineering and sophisticated Digital Signal Processing methods. 
		</p>
		<p>
			I got the first prize in my category, Embedded Systems (<span role="img" aria-label="Partying Face">🥳</span>). But that only was the beginning of a whole new journey I could not possibly think of at this time.
		</p>
		<h3>Our First Product</h3>
		<p>
			Shortly after coming back home, I was convinced: <i>Let’s turn that project into a product!</i>
		</p>

		<Figure>
			<Figure.Image
				alt="Img alt"
				src="/img/articles/article1/pic1.png"
			/>
			<Figure.Caption>
				<p>Our first product: <strong>RainTracker</strong>!</p>

				<p>Once you put it in your garden and it is conencted with your WiFi-Hotspot, it measures rainfall and posts the data to an app on your smartphone. Plus it would warn you against severe storms. Cool, heh? Keep reading...</p>
			</Figure.Caption>
		</Figure>
		<p>
			I knew I could not pull it off alone. I needed to find someone who is just as passionate and hyped about tech as I am. Someone who is driven but would still be willing to invest hard work into a project even if the odds were against us. The choice was clear: Florian, a pal from high school. International Olympiad in Informatics and Google Hash Code finalist. He has been helping me along my way and we both knew we would be a perfect fit to achieve incredible things. He would be concerned with software, data processing and networking. I would complement him with hardware development and managing business relations. Not even one month later, we founded XOrigin.
		</p>	

		<h3>Another Startup Raised $ 2.8 M With Our Tech</h3>

		<p>
			As we talked with people about our idea, our hype started to fade. <i>“Why would someone need your product?”</i>, <i>“Why would someone spend $200 on your system if they can just download a free weather app?”</i>, <i>“Your weather station network will never be dense enough to noticeably increase weather forecasting accuracies”</i>. That hurt. As we lost faith, we started working on side projects. We lost focus. For months, we were in a phase of indecision. We were not even looking forward to talking with people. We were looking out for other ideas but quietly hoped for solutions that would bring our weather station to market. We built high-end sales pages, ran ads on Facebook and Instagram. Whatever we tried, it just seemed as if nobody wanted our product.
		</p>

		<p>
			After pivoting several times in only a few months, we finally gave up on RainTracker. Three months later, BOOM! An American company, WeatherFlow, launches a weather station that literally could have been ours and raises <strong>$ 2.8 Million</strong> on Kickstarter and Indigogo combined. 
		</p>

		<Figure>
			<Figure.Image
				alt="Img alt"
				src="/img/articles/article1/pic2.png"
			/>
			<Figure.Caption>
				<p>The eMail we received from WeatherFlow</p>
			</Figure.Caption>
		</Figure>

		<p>
			Surprisingly they already reached out to us at the beginning of 2019. We are not claiming that they have stolen our idea in any way. They did an amazing job and we are glad that the technology we both were working on is currently available and set out to improve the lives of others.
		</p>

		<h3>What We Learned</h3>

		<p>That journey made us learn a lesson for life:</p>

		<p><i>Don’t be stuck in indecision. Either give everything you got to make your vision come true. Identify yourself with that vision and have no doubt. Else, drop it all and move on. Doubt almost inevitably leads to Failure.</i></p>
	</>);
}

function getContentDE() {
	return (<>
		<p>
		Mai 2019. Für die letzten zwei Jahre habe ich an einem Projekt gearbeitet, um Sturmvorhersagen zu verbessern. Nun war es so weit: Ich wurde als Finalist für den weltweit größten Wissenschaftswettbewerb, die Intel International Science and Engineering Fair 2019 in Phoenix, Arizona ausgewählt. Mein Projekt umfasste die Themen Internet of Things, Machine Learning, Prototypenentwicklung und digitale Signalverarbeitung.
		</p>
		<p>
			In meiner Kategorie Embedded Systems konnte ich einen ersten Platz erreichen (<span role="img" aria-label="Partying Face">🥳</span>). Dies war aber nur der Anfang einer ganz neuen Reise, die ich mir zu dieser Zeit kaum vorstellen konnte.
		</p>
		<h3>Unser erstes Produkt</h3>
		<p>
		Kurz nachdem ich wieder zu Hause ankam stand fest: <i><strong>Aus dem Projekt muss ein serienreifes Produkt werden!</strong></i>
		</p>

		<Figure>
			<Figure.Image
				alt="Img alt"
				src="/img/articles/article1/pic1.png"
			/>
			<Figure.Caption>
				<p>Unser erstes Produkt: <strong>RainTracker</strong>!</p>

				<p>
					Sobald der RainTracker im Garten aufgestellt und mit dem WiFi-Hotspot verbunden ist, misst er Regen und sendet die Daten an eine App auf dem Smartphone. Außerdem warnt es vor bevorstehenden Unwettern! Cool, oder? Lese weiter...
				</p>
			</Figure.Caption>
		</Figure>
		<p>
		Allerdings wusste ich, dass es für mich allein unmöglich wäre, sowas umzusetzen. Ich brauchte jemanden, der genauso interessiert an Technik ist wie ich.  Jemanden, der höchstmotiviert ist und auch dann noch hinter unserer Vision steht, wenn es mal nicht so gut läuft. Mir war sofort klar, wer all das hat: Florian, ein Freund mit dem ich zur Schule gegangen bin. Preisträger bei der International Olympiad in Informatics und Finalist bei Google Hash Code. Er stand mir auf meinem Weg immer zur Seite und wir beiden wussten, dass wir uns perfekt ergänzen würden. Florian würde sich um die Software und Datenverarbeitung kümmern und ich ihn mit Engineering und Businessmanagement unterstützen. Kaum einen Monat später war XOrigin gegründet.
		</p>	

		<h3>Ein Startup in den USA sammelt 2,8 Millionen US-Dollar Investorengelder mit Unserem Produkt</h3>

		<p>
		Umso mehr wir mit anderen Leuten über unser Vorhaben gesprochen haben, desto weniger glaubten wir an unsere Vision. <i>„Warum würde jemand euer Produkt brauchen?“</i>, <i>„Warum würde jemand 200 € für euer Produkt ausgeben?“</i>, <i>„Euer Netzwerk wird nie dicht genug sein, um Unwetter vorhersagen zu können!“</i>. Als wir langsam an Zielstrebigkeit verloren hatten, begonnen wir Nebenprojekte anzufangen. Wir haben den Fokus verloren. Für Monate hatten wir keine klaren Entscheidungen getroffen, haben aufwendige Sales-Seiten entwickelt und Werbung auf Facebook und Instagram geschaltet. Egal was wir probierten, niemand wollte unser Produkt kaufen.
		</p>

		<p>
		Nach drei Monaten haben wir RainTracker aufgegeben. Kurze Zeit später im Dezember 2019 veröffentlicht das amerikanische Startup WeatherFlow ihre Smart-Home Wetterstation mit Verbesserung von Wettervorhersagen durch maschinelles Lernen und sammelt auf Kickstarter und Indigogo zusammen <strong>2,8 Millionen US-Dollar</strong>.
		</p>

		<Figure>
			<Figure.Image
				alt="Img alt"
				src="/img/articles/article1/pic2.png"
			/>
			<Figure.Caption>
				<p>The eMail we received from WeatherFlow</p>
			</Figure.Caption>
		</Figure>

		<p>
		Interessanterweise bekamen wir bereits Anfang 2019 eine Mail von WeatherFlow. Wir behaupten nicht, dass WeatherFlow unsere Idee „gestohlen“ hat – ganz im Gegenteil: Wir freuen uns, dass jemand es geschafft hat, unsere Idee zu verwicklichen und dazu beiträgt, das Leben vieler Menschen zu verbessern.
		</p>

		<h3>Was wir daraus gelernt haben</h3>

		<p>Aus dieser Story haben wir einiges mitgenommen:</p>

		<p><i>Vermeide Unentschlossenheit. Stecke entweder alles, was du hast in eine Idee und finde heraus, ob Menschen sich dafür interessieren. Identifiziere dich mit deiner Vision und zweifle nicht an ihr. Wenn doch, lasse sie fallen. Zweifel führt fast immer unausweichlich zum Scheitern.</i></p>
	</>);
}

function getPrevEN(){
	return (<>
		<p>
			May 2019. I am all set-up to compete at the Intel International Science and Engineering Fair 2019 in Phoenix (Arizona), the world’s largest science competition for pre-university students of its kind. For the past 2 years, I have been working on a self-initiated project aiming to improve short-term storm forecasting accuracies. It involved Internet of Things, Machine Learning, Electrical Engineering and sophisticated Digital Signal Processing methods. 
		</p>
		<p>
			I got the first prize in my category, Embedded Systems (<span role="img" aria-label="Partying Face">🥳</span>). But that only was the beginning of a whole new journey I could not possibly think of at this time.
		</p>
	</>);
}

function getPrevDE(){
	return (<><p>
		Mai 2019. Für die letzten zwei Jahre habe ich an einem Projekt gearbeitet, um Sturmvorhersagen zu verbessern. Nun war es so weit: Ich wurde als Finalist für den weltweit größten Wissenschaftswettbewerb, die Intel International Science and Engineering Fair 2019 in Phoenix, Arizona ausgewählt. Mein Projekt umfasste die Themen Internet of Things, Machine Learning, Prototypenentwicklung und digitale Signalverarbeitung.
		</p>
		<p>
			In meiner Kategorie Embedded Systems konnte ich einen ersten Platz erreichen (<span role="img" aria-label="Partying Face">🥳</span>). Dies war aber nur der Anfang einer ganz neuen Reise, die ich mir zu dieser Zeit kaum vorstellen konnte.
		</p></>);
}

export const data = {
	id: "our-origin",
	title: ["How Not to Launch a Startup", "Wir sind gescheitert?"],
	subtitle: ["We Dropped a 2.8 Million Dollar Idea and another Startup Made it Happen",
						 "Wie wir eine 2,8 Millionen Dollar Idee verpasst haben"],
	author: "max",
	titleImg: "/img/articles/article1/titleimg.png",
	date: new Date(2020, 3, 21),
	preview: [getPrevEN(), getPrevDE()],
	content: [getContentEN(), getContentDE()],
}