import React from 'react';
import Figure from 'react-bootstrap/Figure';
import ImageFigure from '../components/ImageFigure';
// first: en, second: de

function getContentEN(){
	return (<>
<p>
	In February 2020, more than 10,000 teams from all over the world came together to compete in the Google Hashcode competition. What sets this contest apart from all the other programming competitions, is the type of problems posed. Here, you solve optimization problems close to the real world, often inspired by Google's challenges. The past problems reached from providing Internet access via high altitude balloons (like in Google Loon) to optimizing video streaming servers needed for Youtube.
</p>

<p>
	Together with my team, we have successfully participated in many past Hashcode rounds, so we knew what to expect in this year's final. Or so we thought...
</p>

<h3>Learning from the past</h3>

<p>
	A Hashcode problem set consists of only one task statement, but your score is the result of your solutions for roughly six different test cases. Every test case describes a different situation with potentially different bottlenecks. In the video streaming example, there may be one test case with only a few servers, while another test comprises many servers but only a few exceptionally long videos.
	<br />
	When we started taking part in the Hashcode competitions, we were able to score remarkably high using a simple approach. We developed a greedy strategy that we could tune with as many variables as possible. This means we chose a grading function which tells us, for example, how valuable a video is. This score depends on many variables like the number of people requesting it, the amount of space it requires, etc. Then, we tried to adapt to how we weight the different factors for each scenario. Back in the days, this approach was extremely effective. I firmly remember how we placed 7th in the qualification round 2018 by only spending half of our time programming and the other half on fine-tuning our parameters.
</p>

<p>
	But with time, the problems changed. In the Final 2019, the scenarios were so different that we needed to develop multiple, completely independent solutions. We adapted to this change by dedicating one team member to inspect the test cases. This way, we were able to solve all the scenarios well and secured place 13 in last year's final. In this year's final, however, the problem was fundamentally different.
</p>
<ImageFigure
	alt="Hashcode Final 2019"
	src="/img/articles/hashcode/hashcode_2019.jpg"
	credits="Google © 2019"
	>
		<p>The Hashcode Final 2019 in the Google office in Dublin. Unfortunately, the on-site event this year was canceled.</p>
</ImageFigure>

<h3>The Task</h3>
<p>
	Due to the Coronavirus, the Google Hashcode Finals 2020 did not take place in Dublin, Irland, but happened online. The location was not the only thing that changed though. This year's task revolved around manufacturing planning. To summarize the statement:
</p>
<p>
	You have a list of manufacturing tasks and extendible robot arms.
</p>

<h5>Task</h5>
<p>
	Every task consists of a sequence of locations where the robot arm must pick up or deliver the product. This means, to complete a task, one robot arm needs to pick the product up at the first location and then, without stopping, must visit a list of locations where the product is assembled. Important to note is that tasks considerably differ in the number of spots, the distance between locations, and the score returned for completion.
</p>

<h5>Robot arms</h5>
<p>
	A robot arm can only be placed at certain, so-called mounting points. Once installed, the arms can extend like in the arcade game Snake. Similar to the game, a robot arm must not collide with his tail or another robot's tail. For that, a robot arm can retreat to shorten its tail. A robot can move its head one unit in one second.
</p>

<ImageFigure
	alt="Illustration"
	src="/img/articles/hashcode/illustration.PNG"
	credits="Google © 2020"
	>
		<p>The robot is mounted at on one the blue mounting points and now extends to reach the assembly points.</p>

</ImageFigure>

<p>
	Now, given a limited amount of time and a fixed number of robots, it is your job to select tasks and plan the robot's movements to maximize the score, which is the sum of the scores from all completed tasks.
</p>

<h3>Our Solution</h3>
<p>
	As it turns out, the fact that every robot leaves a trail makes the problem quite difficult. Coming up with a strategy that distributes the tasks <i>somehow</i> and moves the robots without collisions proved to be enough of a challenge for the four hours.
	<br />
	After this realization, we made a rough plan of how our approach would work. First, we start with one robot and try to find a well-suited mounting point. Then, we try to finish the best tasks from that mounting point. Only then, having calculated the robot's movement for the total time, we move to the next robot. This way, we already know the other robot's current and future positions so that we can avoid them.
</p>

<h5>Selecting the Mount Point</h5>
<p>
	We started coding the mounting point selection. Here, we used a standard greedy approach. For every mounting point, we estimated which remaining tasks we would be able to complete within the available time. Of course, we could only use rough approximation by looking at how many locations we needed to visit per task and how far the locations were apart. We could, therefore, not account for possible delays caused by other robot's tails blocking the shortest path. 
</p>

<h5>Moving the Robots</h5>
<p>
	While one teammate was coding the mounting point selection, another teammate and I started working on the motion planner for the robots. Our question was, how does a robot move from one assembly location to the next one? One option would be to take the shortest pathway. Unfortunately, as every movement, except for a retreat, causes the tail to grow, after a few paths, the robot's tail will make it impossible to move. One the other hand, retreating completely after every visited location keeps the tail short, but often takes longer than necessary. So, in the contest, we chose a compromise: We let the robot retreat, but only until the path using the rest of the tail is as short as the minimal distance from the mounting point (see figure).
</p>
<ImageFigure
	alt="Illustration"
	src="/img/articles/hashcode/movement.png"
	>
		<p>To move from point A to point B, the robot arm retreats to point R and then moves to B.</p>

</ImageFigure>
<p>
	Now, almost half of our time was already up. Still, our program was far from completed as we realized a severe problem.
</p>

<h5>Avoiding Collisions</h5>
<p>
	The real challenge, we realized, is the collision avoidance. Until now, we stored for every point in our construction hall and every time step, if the spot was used by any robot. As we process the robots one after the other, this would work perfectly, we thought.
	<br />
	The problem arises from the robot's tail. When we see a field that is free at the current time step, we think it is safe for our robot to visit it. However, the field may be assigned to another assembly arm in the future. But when we visit the field, we leave our tail there, which then blocks the other robot. This would destroy our approach of planning one robot after the other.
	<br />
	Running short on time, we needed a fix quickly. Fortunately, my teammate proposed a brilliant solution. When we visit a currently-free field, we keep track of how long it will be free. Then we ensure to retreat no later than this time step to free the space for a different assembly arm.
</p>

<p>
	At this moment, we were almost three hours into the competition. The scoreboard was going to freeze in a few minutes and we had yet to submit anything.
</p>

<h3>The Final Hour</h3>
<p>
	Having programmed for many hours, here comes the crucial moment: Will the code work correctly?
	<br />
	No. Of course not. We encounter multiple issues: our program crashes, the output format is not correct all the time, and we produce collisions between robots. Determined to get our solution running, we work together to fix one bug after the other. Then, finally, only seconds before the scoreboard freeze, we manage to submit our first working solution to one of the test cases.
</p>

<Figure>
	<video autoPlay loop muted className="figure-img img-fluid">
		<source src="/img/articles/hashcode/animation.mp4" type="video/mp4"/>
	</video>
	<Figure.Caption>
		<p>Google provided a helpful animation tool that helped understanding how our program controlled the robots. </p>
	</Figure.Caption>
</Figure>

<p>
	Why did we only submit one test case? Because we have a new problem: our solution is too slow, far too slow. Calculating how to move the robot to avoid any collisions takes so long that we barely finish placing one assembly robot while running our program for five minutes.
</p>

<p>
	In the last hour, we did everything to speed up our algorithm, instead of searching for a path in all directions, we searched target-oriented, instead of looking at all tasks, we limited us to the nearest tasks. In the end, our effort paid off and we were able to generate decent solutions for all scenarios. Still, in one test case, we only utilized 17 out of the 50 available robots because our program did not finish the calculation in time. Nevertheless, with 4,327,978 points in total, we are proud to take <a href="https://codingcompetitions.withgoogle.com/hashcode/archive/2020" title="See Scoreboard">10th place</a> as the team <i>Prove By Submission</i> in the 2020 edition of the Google Hashcode competition.
</p>
<Figure>
	<Figure.Image
		alt="Certificate"
		src="/img/articles/hashcode/Certificate.PNG"
	/>
	<Figure.Caption>
		<p>As the competition was hosted online only, the certificates were digital too.</p>
	</Figure.Caption>
</Figure>

<h3>Conclusion</h3>
<p>
	This year's Google Hashcode was not like any round before. Instead of programming a straight-forward greedy approach and then optimizing parameters or looking deeply into the different scenarios, this time, we were mostly just focused on getting our program running. Without any fancy optimizations, we ranked high as the other teams seemingly also struggled.
	<br />
	Personally, I like this change as it challenges the teams and reduces the amount of "luck" needed to find the perfect set of parameters.
</p>

<Figure>
	<Figure.Image
		alt="Our score distripution"
		style={{maxHeight: "350px"}}
		src="/img/articles/hashcode/score_distribution.PNG"
	/>
	<Figure.Caption>
		<p>Our scores in the different scenarios.</p>
	</Figure.Caption>
</Figure>

<h3>Looking Further</h3>
<p>
	Of course, our solution is far from perfect. So if you want to check out the problem yourself, you can find the statement <a href="https://codingcompetitions.withgoogle.com/hashcode/archive" title="Google Hashcode Archive">here</a>. If you are interested in other team's solutions, check out the blog post on <a href="https://codeforces.com/blog/entry/76548">Codeforces</a>.
</p>
	</>);
}

function getContentDE() {
	return (<>
		<p>
			Mehr als 10,000 Teams aus der ganzen Welt stellten sich dieses Jahr der Herausforderung des Google Hashcodes. Das Besondere an diesem Wettbewerb sind die speziellen Aufgaben. Hier stellt Google Optimierungsprobleme aus der echten Welt, oft sogar aus dem Kontext eines Google Projektes. In den vergangenen Jahren gab es so zum Beispiel Aufgaben, bei denen man mit Gasballons Internet in entlegene Gebiete bringen sollte (wie Googles <i>Project Loon</i>) oder Videostreaming-Server für Youtube optimieren sollte.
		</p>
		<p>
			Zusammen mit meinem Team habe ich schon erfolgreich an einigen vergangenen Hashcode Runden teilgenommen. Also wussten wir, was uns dieses Jahr im Finale erwarten würde. Zumindest dachten wir das...
		</p>
		
		<h3>Aus der Vergangenheit lernen</h3>

		<p>
			Bei Hashcode gibt es nur eine einzige Aufgabe zu bearbeiten, allerdings setzt sich die Punktzahl aus der Summe der Punktzahlen von circa sechs Testcases zusammen. Jeder Testcase beschreibt ein bestimmtes Szenario mit unterschiedlichen Begrenzungen. In dem Videostreaming Beispiel gab es so etwa in einem Szenario nur sehr wenige Server, in einem anderen Szenario deutlich mehr Server, dafür aber besonders lange Videos.
			<br />
			Als wir vor einigen Jahren angefangen haben beim Google Hashcode mitzumachen, konnten wir mit einem relativ einfachen Ansatz bereits sehr gut Punktzahlen erreichen. Wir sind dabei wie folgt vorgegangen. Als Erstes entwarfen wir eine <i>Greedy-Strategie</i>, die von möglichst vielen relevanten Parametern abhängt. Das bedeutet, wir erstellten eine Funktion, die einen Score berechnet, welcher uns sagte, wie <i>wichtig</i> zum Beispiel ein Video ist. Dieser Score hängt dann von vielen Variablen ab, wie die Anzahl an Anfragen für das Video, den Speicherbedarf und so weiter. Dann versuchten wir die Gewichtungen der Parameter für die verschiedenen Szenarien anzupassen. Damals war diese Strategie erstaunlich erfolgreich. Ich erinnere mich noch an die Qualifikationsrunde 2018, bei der wir den siebten Platz belegten und dabei nur die Hälfte der Zeit mit Programmieren verbrachten und die restliche Zeit Feinanpassungen der Parameter machten.
		</p>
		
		<p>
			Doch mit der Zeit änderten sich auch die Probleme. Im Finale 2019 waren die verschiedenen Szenarios so verschieden, dass wir mehrere, komplett unabhängige Lösungen implementieren mussten. Wir passten uns an, in dem wir verstärkt die einzelnen Testcases analysierten. Somit gelang es uns für alle Szenarien gute Lösungen zu generieren und im Finale letztes Jahr einen 13. Platz zu belegen. Dieses Jahr, sollte diese Taktik allerdings nicht aufgehen.
		</p>

		<ImageFigure
			alt="Hashcode Final 2019"
			src="/img/articles/hashcode/hashcode_2019.jpg"
			credits="Google © 2019"
			>
				<p>Das Hashcode Finale 2019 im Google Office in Dublin. Leider musste das Event vor Ort dieses Jahr aus gegebenen Umständen ausfallen.</p>
		</ImageFigure>
		
		<h3>Die Aufgabenstellung</h3>
		<p>
			Wegen des Coronavirus' konnte das Google Hashcode Finale 2020 leider nicht wie geplant in Dublin stattfinden. Stattdessen wurde der Wettbewerb online ausgetragen. Das war allerdings nicht die einzige Änderung. Die Aufgabenstellung handelte dieses Jahr um das Planen einer Fabrikanlage, hier die Zusammenfassung:
		</p>
		<p>
			Gegeben ist eine Liste an Fertigungsaufträgen und eine Menge an Roboterarmen.
		</p>
		
		<h5>Die Fertigungsaufträge</h5>
		<p>
			Jeder Auftrag wird als eine Sequenz an Punkten angegeben, die von einem Roboterarm besucht werden müssen. Um also ein Fertigungsauftrag abzuschließen, muss das Produkt an der ersten Stelle vom Roboter aufgehoben werden und dann, ohne abzusetzen, an die verschiedenen Positionen bewegt werden, wo es dann verarbeitet wird. Wichtig ist hierbei, dass es durchaus große Unterschiede zwischen den Aufträgen gibt, insbesondere hinsichtlich der Anzahl an Positionen, deren Distanz und der Punktzahl, die man für die Fertigstellung des Produktes erhält.
		</p>
		
		<h5>Die Roboterarme</h5>
		<p>
			Ein Roboterarm kann nur an bestimmten, sogenannten <i>Mounting Points</i> installiert werden. Ist er einmal dort platziert, kann sich der Arm beliebig lang ausfahren. Das Ganze erinnert etwas and das Spiel Snake. Ähnlich wie bei dem Spiel darf der Roboterarm auch nicht mit seinem eigenen "Schwanz" oder dem Schwanz eines anderen Roboters kollidieren. Dazu ist es möglich den Arm wieder einzufahren um den Schwanz wieder zu verkürzen. Pro Sekunde kann sich der Roboter dabei um eine Einheit bewegen.
		</p>
		
		<ImageFigure
			alt="Illustration"
			src="/img/articles/hashcode/illustration.PNG"
			credits="Google © 2020"
			>
				<p>
					Der Roboter ist an einem der blauen Mounting Points platziert und fährt sein Kopf aus um die Produktionsstätten zu erreichen.
				</p>
		
		</ImageFigure>
		
		<p>
			Nun ist die Aufgabe in einer begrenzten Zeit und mit einer fixen Anzahl an Robotern die besten Fertigungsaufträge auszuwählen und die Roboter so zu steuern, dass keine Kollisionen auftreten. Dabei setzt sich die Punktzahl aus der Summe aller abgeschlossenen Fertigungsprozessen zusammen.
		</p>
		
		<h3>Unsere Lösung</h3>
		<p>
			Wie sich herausstellte, war das Problem recht herausfordern, da die Roboter mit ihrem Schwanz viele Wege blockieren. Überhaupt <i>irgendeine</i> Strategie zu finden, die die Aufgaben verteilt und die Roboter ohne Kollisionen bewegt, stellte sich als hinreichende Herausforderung für die vier Stunden heraus.
			<br />
			Nach dieser Erkenntnis machten wir einen groben Plan wie eben so ein Ansatz funktionieren könnte. Als Erstes fingen wir mit einem Roboter an und suchten für ihn nach einem geeigneten Mounting Point. Dann versuchten wir mit diesem Roboter möglichst viele Fertigungsprozesse in der gegebenen Zeit abzuschließen. Erst dann, nachdem wir die Bewegungen des Roboters über die gesamte Zeit geplant hatten, betrachteten wir den nächsten Roboter. So kannten wir bereits die aktuelle, als auch die zukünftige Position des anderen Roboters und konnten diese Positionen vermeiden.
		</p>
		
		<h5>Auswahl der Mounting Points</h5>
		<p>
			Wir fingen mit der Auswahl der Mounting Points an. Dafür benutzten wir einen Standard <i>Greedy-Ansatz</i>. Für jeden Mounting Point schätzten wir ab, welche der verbleibenden Aufträge wir in der Zeit wohl schaffen könnten. Natürlich war das nur durch eine grobe Näherung möglich, indem wir betrachteten, wie viele Positionen wir pro Auftrag besuchen müssten und wie weit diese Positionen auseinander liegen. Eventuelle Verzögerungen durch blockierte Bahnen durch andere Roboter ignorierten wir hierfür.
		</p>
		
		<h5>Bewegung der Roboter</h5>
		<p>
			Während ein Teammitglied die Mounting Point Auswahl programmierte, fing ich zusammen mit einem anderen Teammitglied bereits mit der Bewegungsplanung der Roboter an. Wir beschäftigten uns mit der Frage, wie ein Roboter von einer Produktionsstätte zur nächsten kommt. Eine Option wäre, den kürzesten Weg zu nehmen. Da allerdings jede Bewegung, außer das Zurückziehen, eine Verlängerung des Schwanzes bedeutet, wäre es so sehr schnell nicht mehr möglich den Roboter zu bewegen. Andererseits, verbraucht das komplette Zurückziehen und Wiederausfahren wertvolle Zeit, auch wenn dadurch der Schwanz sehr kurz bleibt. Also wählten wir im Wettbewerb einen Kompromiss. Der Roboter fährt sich so lange ein, bis der Weg bei Benutzung des verbliebenen Schwanzes genauso lang ist, wie der Weg vom Mounting Point (siehe Abbildung).
		</p>
		<ImageFigure
			alt="Illustration"
			src="/img/articles/hashcode/movement.png"
			>
				<p>
					Um vom Punkt A zum Punkt B zu kommen, zieht sich der Roboter bis zum Punkt R zurück und fährt dann wieder bis B aus.
				</p>
		
		</ImageFigure>
		<p>
			Es war bereits fast die Hälfte der Zeit vergangen und unser Programm war noch lange nicht lauffähig, als wir ein ernstes Problem bemerkten.
		</p>
		
		<h5>Kollisionen vermeiden</h5>
		<p>
			Die größte Herausforderung, so mussten wir feststellen, war die Kollisionsvermeidung. Bis zum jetzigen Zeitpunkt speicherten wir für jeden Punkt in unserer Fabrik und jeden Zeitschritt, ob das Feld von einem Roboter belegt war. Da wir die Roboter nacheinander planten, dachten wir, es würde somit wunderbar funktionieren Kollisionen zu vermeiden.
			<br />
			Der Schwanz der Roboter verursachte allerdings ein nicht bedachtes Problem. Wenn wir ein Feld sehen, das zum aktuellen Zeitpunkt frei ist, nehmen wir an, dass es für unseren Roboter sicher ist, dieses Feld zu belegen. Jedoch kann das Feld in der Zukunft für einen anderen Arm reserviert sein. Da unser Roboter nun beim Benutzten des Feldes seinen Schwanz dort lässt, blockiert er damit den anderen Roboterarm. Das zerstört unseren Ansatz, bei dem wir einen Roboter nach dem anderen planen.
			<br/>
			Wir lagen schlecht in der Zeit, also musste eine schnelle Lösung her. Glücklicherweise hatte mein Teamkollege eine brillante Idee. Wenn wir ein Feld besuchen, merken wir uns wie lange dieses Feld noch frei ist. Dann stellen wir sicher, dass wir uns bis zu diesem Zeitpunkt wieder zurückgezogen haben, um den ankommenenden Roboter nicht zu behindern.
		</p>
		
		<p>
			Zu diesem Zeitpunkt waren wir bereits seit fast drei Stunden am Programmieren. Das Scoreboard war kurz vor dem Einfrieren (in der letzten Stunde vor Schluss aktualisiert sich das Scoreboard nicht mehr um die Spannung zu erhöhen) und wir hatten noch keine Lösung eingereicht.
		</p>
		
		<h3>Die letzte Stunde</h3>
		<p>
			Nach vielen Stunden Programmieren kam dann der entscheidende Augenblick: Wird unser Code korrekt funktionieren?
			<br />
			Nein. Natürlich nicht. Es treten Laufzeitfehler auf, unser Ausgabeformat ist noch falsch und wir produzieren Kollisionen zwischen Robotern. Entschlossen unser Programm dennoch zum Laufen zu bekommen, arbeiten wir unter Hochdruck um die Fehler zu beheben. Dann, nur wenige Momente vor dem Einfrieren des Scoreboards, gelingt es uns für ein Szenario eine Lösung hochzuladen.
		</p>
		
		<Figure>
			<video autoPlay loop muted className="figure-img img-fluid">
				<source src="/img/articles/hashcode/animation.mp4" type="video/mp4"/>
			</video>
			<Figure.Caption>
				<p>
					Google stellte uns eine hilfreiche Animation zur Verfügung, die uns half nachzuvollziehen, wie unser Programm die Roboter bewegt.
				</p>
			</Figure.Caption>
		</Figure>
		
		<p>
			Warum konnten wir nur eine Lösung für ein einziges Szenario erstellen? Wir hatten ein neues Problem: unser Programm war zu langsam, viel zu langsam. Das Berechnen, wie der Roboter sich bewegen soll ohne Kollisionen zu verursachen dauerte so lange, dass selbst nach fünf Minuten Rechenzeit gerade erst der erste Roboter platziert wurde.
		</p>
		
		<p>
			In der letzten Stunde taten wir alles, um unseren Algorithmus zu beschleunigen. Anstatt in alle Richtungen nach einem Pfad zu suchen, suchten wir zielgerichtet, statt alle Aufträge zu betrachten, beschränkten wir uns auf die nächstgelegenen. Schlussendlich zahlte sich unsere Anstrengung aus und wir konnten für alle Szenarios ordentliche Lösungen generieren. Trotzdem benutzten wir in einem Szenario nur 17 der 50 zur Verfügung stehenden Roboter, da unser Programm nicht mehr rechtzeitig mit der Bewegungsberechnung fertig wurde. Dennoch sind wir stolz als Team <i>Proof By Submission</i> mit 4.327.978 Punkten einen <a href="https://codingcompetitions.withgoogle.com/hashcode/archive/2020" title="Scoreboard anschauen">zehnten Platz</a> im Finale des Google Hashcodes 2020 zu erreichen.
		</p>
		<Figure>
			<Figure.Image
				alt="Certificate"
				src="/img/articles/hashcode/Certificate.PNG"
			/>
			<Figure.Caption>
				<p>Da der Wettbewerb ausschließlich online stattfand, sind auch die Urkunden digital.</p>
			</Figure.Caption>
		</Figure>
		
		<h3>Fazit</h3>
		<p>
			Dieser Hashcode Wettbewerb hat sich deutlich von den bisherigen Runden unterschieden. Statt eine einfache Greedy-Strategie zu implementieren und dann die Parameter zu optimieren oder verschiedene Lösungen für verschiedene Szenarios zu programmieren, waren wir dieses Mal hauptsächlich damit beschäftigt, unser Programm zum Laufen zu bekommen. Ohne besondere Optimierungen schafften wir es so weit vorne zu landen, da die anderen Teams anscheinend mit ähnlichen Problemen zu kämpfen hatten.
			<br />
			Persönlich finde ich diese Entwicklung sehr gut, da es für die Teams eine größere Herausforderung ist und das nötige "Glück", um die perfekten Parameter zu finden, reduziert.
		</p>
		
		<Figure>
			<Figure.Image
				alt="Our score distripution"
				style={{maxHeight: "350px"}}
				src="/img/articles/hashcode/score_distribution.PNG"
			/>
			<Figure.Caption>
				<p>Unsere Punktzahlen in den verschieden Szenarios</p>
			</Figure.Caption>
		</Figure>
		
		<h3>Ausblick</h3>
		<p>
			Natürlich ist unsere Lösung bei Weitem nicht perfekt. Wenn du dich für selber an dem Problem versuchen möchtest, findest du <a href="https://codingcompetitions.withgoogle.com/hashcode/archive" title="Google Hashcode Archive">hier</a> die Aufgabenstellung. Wenn du dich für weitere Lösungsansätze interessierst, kann ich dir den Blog Artikel auf <a href="https://codeforces.com/blog/entry/76548">Codeforces</a> empfehlen.
		</p>
			</>);
}

function getPrevEN(){
	return (<>
		<p>
			In the Google Hashcode competition 2020, more than 10,000 teams from all over the world came together to solve an optimization problem. What sets this competition apart from all the other programming competitions, is the type of problems posed. Here, you solve problems close to the real world, often inspired by Google's challenges. The past problems reached from providing Internet access via high altitude balloons (like in Google Loon) to optimizing video streaming servers needed for Youtube.
		</p>

		<p>
			Together with my team, we have successfully participated in many past Hashcode rounds, so we knew what to expect in this year's final. Or so we thought...
		</p>
	</>);
}

function getPrevDE(){
	return (<>
	<p>
			Mehr als 10,000 Teams aus der ganzen Welt stellten sich dieses Jahr der Herausforderung des Google Hashcode Wettbewerbs. Das Besondere an diesem Wettbewerb sind die speziellen Aufgaben. Hier nimmt Google Optimierungsprobleme aus der echten Welt, oft sogar aus dem Kontext eines Google Projektes. In den vergangenen Jahren gab es so zum Beispiel Aufgaben bei der man per Gasballons Internet in entlegene Gebiete bringen sollte (wie Googles <i>Project Loon</i>) oder Videostreaming-Server für Youtube optimieren sollte.
		</p>
		<p>
			Zusammen mit meinem Team habe ich schon erfolgreich an einigen vergangenen Hashcode Runden teilgenommen. Also wussten wir, was uns dieses Jahr im Final erwarten würde. Zumindest dachten wir das...
		</p>
	</>);
}

export const data = {
	id: "hashcode",
	title: ["Ranking top 10 at the Google Hashcode Final 2020", "Top 10 beim Google Hashcode Finale 2020"],
	subtitle: ["Why Everything was Different this Year",
						 "Warum dieses Jahr alles anders war"],
	author: "florian",
	titleImg: "/img/articles/hashcode/florian_hashcode.jpg",
	date: new Date(2020, 5-1, 3),
	preview: [getPrevEN(), getPrevDE()],
	content: [getContentEN(), getContentDE()],
}
