import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { useTranslation } from "react-i18next";
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import './MyFooter.css';
import Col from 'react-bootstrap/Col';
import LangSwitcher from './LangSwitcher';

function MyFooter() {
	const { t } = useTranslation();
	return (
		<footer>
			<Container>
				<Row>
					<Col className="d-flex flex-column" md={4}>
						<div className="text-center"><img
								alt=""
								src="/img/logo.png"
								height="40"
								className="d-inline-block align-top"
						/></div>
						<div className="w-100 text-center pt-3">info@xorigin.tech</div>
						<div className="social justify-content-center">
						<a href="https://www.linkedin.com/company/xorigintech/"><img className="social-icon" alt="LI" src="/img/icon/linkedin.png" /></a>
						<a href="https://instagram.com/xorigintech"><img className="social-icon" alt="IG" src="/img/icon/instagram.png" /></a>
						<a href="https://twitter.com/XOriginTech"><img className="social-icon" alt="TW" src="/img/icon/twitter.png" /></a>
						</div>
					</Col>
					<Col className="d-flex links flex-wrap">
						<Row className="w-100">
							<Nav.Link as={Link} smooth to="/#solutions">{t("nav.solutions")}</Nav.Link>
							<Nav.Link as={Link} smooth to="/blog">{t("nav.blog")}</Nav.Link>
							<Nav.Link as={Link} smooth to="/#team">{t("nav.team")}</Nav.Link>
						</Row>
						<Row className="w-100 lang-wp">
							<LangSwitcher />
						</Row>
					</Col>
				</Row>
				<hr />
				<Row className="legal">
					<Col className="mb-4">
						Copyright © 2020 XOrigin UG (haftungsbeschränkt)
					</Col>
					<Col className="d-flex justify-content-end">
						<a href="https://airtracker.xorigin.tech/impressum">{t("footer.imprint")}</a>
						<a href="https://airtracker.xorigin.tech/datenschutz">{t("footer.privacy")}</a>
					</Col>
				</Row>	
			</Container>
		</footer>
	);
}

export default MyFooter;