import React from 'react';
import { useTranslation } from "react-i18next";
import Container from 'react-bootstrap/Container';
import './SectionTitle.css';

function SectionTitle(){
	const { t } = useTranslation();
	return (
		<Container>
			<div className="sectionTitle">
				<h1>{t("title.slogan")}</h1>
				<h4>{t("title.subslogan")}</h4>
			</div>
		</Container>
	);
}
export default SectionTitle;