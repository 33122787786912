import React from 'react';
import { useTranslation } from "react-i18next";
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './SectionContact.css';



function SectionTitle(){
	const { t } = useTranslation();
	const [show, setShow] = React.useState(false);

	function Popup() {
		const handleClose = () => setShow(false);

		return (
			<>
				<Modal show={show} onHide={handleClose}>
					<Modal.Header closeButton>
						<Modal.Title>{t("contact.success")}</Modal.Title>
					</Modal.Header>
					<Modal.Body>{t("contact.msg")}</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							{t("contact.close")}
						</Button>
					</Modal.Footer>
				</Modal>
			</>
		);
	}


	function handleSubmit(e){
		console.log(document.querySelector("#email"));
		e.preventDefault();
		fetch('/contact', {
      method: 'POST',
			body: JSON.stringify(
							{email: document.querySelector("#email").value,
						 text: document.querySelector("#text").value}),
			headers: {'Content-Type': 'application/json'},
		}).then(res => res.json())
		.then((res) => {if(res.ok) setShow(true)});
	}

	return (
		<Container>
			<div className="sectionContact">
				<h2>{t("contact.contact")}</h2>
				<Form onSubmit={handleSubmit} method="post">
					<Form.Group controlId="email">
						<Form.Label>Email address</Form.Label>
						<Form.Control type="email" placeholder="Enter email" />
					</Form.Group>

					<Form.Group controlId="text">
						<Form.Label>Your message</Form.Label>
						<Form.Control as="textarea" rows="7" />
					</Form.Group>
					<Button variant="primary" type="submit">
						Submit
					</Button>
				</Form>
			</div>
			<Popup />
		</Container>
	);
}
export default SectionTitle;