import React from 'react';
import { useTranslation } from "react-i18next";
import './LangSwitcher.css';


function LangSwitcher(){
	const { t, i18n } = useTranslation();
	const otherLang = (i18n.language.startsWith("de") ? "en" : "de");
	return (
		<div className="langSwitcher d-flex">
			<div>{t("footer.showIn")}</div>
			<div className="d-flex lang" onClick={() => i18n.changeLanguage(otherLang)}>
				<div className={otherLang} />
				<div>{otherLang}</div>
			</div>
    </div>
	);
}

export default LangSwitcher;