import React from 'react';
import { useLocation } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import { useTranslation } from "react-i18next";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import { LinkContainer } from "react-router-bootstrap";
import './MyNavbar.css';

function MyNavbar() {
	const { t } = useTranslation();
	const { pathname } = useLocation();

  React.useEffect(() => {
		console.log("route changed");
  }, [pathname]);
	return (
		<Container>
      <Navbar collapseOnSelect bg="white" variant="light" expand="md" className="justify-content-between">
        <Navbar.Brand as={Link} smooth to="/">
					<img
              alt=""
              src="/img/logo.png"
              height="40"
              className="d-inline-block align-top"
            />
        </Navbar.Brand>

				<Navbar.Toggle aria-controls="basic-navbar-nav" />
  			<Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
					<LinkContainer to="/#solutions"><Nav.Link >{t("nav.solutions")}</Nav.Link></LinkContainer>
					<LinkContainer to="/blog"><Nav.Link >{t("nav.blog")}</Nav.Link></LinkContainer>
					<LinkContainer to="/#team"><Nav.Link >{t("nav.team")}</Nav.Link></LinkContainer>
				</Navbar.Collapse>
      </Navbar>
		</Container>
	);
}

export default MyNavbar;