import React from 'react';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import './App.css';
import MyNavbar from './components/MyNavbar'
import SectionTitle from './components/SectionTitle'
import SectionSolutions from './components/SectionSolutions';
import {SectionBlog} from './components/SectionBlog';
import SectionContact from './components/SectionContact';
import SectionBlogOverview from './components/SectionBlogOverview';
import PageBlog from './components/PageBlog';
import MyFooter from './components/MyFooter';

function App() {
  return (
    <Router>
      <div className="superWrapper">
        <MyNavbar />
        <Switch>
          <Route path="/blog/:id">
            <PageBlog />
          </Route>
          <Route path="/blog">
            <SectionBlogOverview />
          </Route>
          <Route path="/">
            <SectionTitle />
            <SectionSolutions />
            <SectionBlog />
            <SectionContact />
          </Route>
        </Switch>
        <MyFooter />
      </div>
    </Router>
  );
}

export default App;
