import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { useTranslation } from "react-i18next";
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import {MdNavigateNext} from "react-icons/md"
import './SectionBlog.css';

import {getArticles} from "../articles";
import {renderDate} from "../util";

function AuthorInline({author, date}) {
	const isMax = author !== "florian";
	const img = isMax ? "/img/max_thumb.jpg" : "/img/flo_thumb.jpg";
	const name = isMax ? "Max" : "Florian";
	const { t } = useTranslation();
	return (
	<div className="author">
		<div className="img-wp">
			<img src={img} alt={name}/>
		</div>
		<div className="authorText">
			<div className="name">{name}</div>
			<div className="date-field">{t("blog.dateOn")} {date}:</div>
		</div>
	</div>
	);
}

function Author({author}){
	const isMax = author !== "florian";
	const img = isMax ? "/img/max_thumb.jpg" : "/img/flo_thumb.jpg";
	const name = isMax ? "Max" : "Florian";
	const role = isMax ? "CEO & Co-Founder" : "Co-Founder";
	
	return (
	<div className="author">
		<div className="img-wp">
			<img src={img} alt={name}/>
		</div>
		<div className="authorText">
			<div className="name">{name}</div>
			<div className="position">{role}</div>
		</div>
	</div>
	);
}

function ArticlePreview({className, data}){
	const {i18n} = useTranslation();
	return (
		<div className={className} >
			<Link to={"/blog/"+data.id} className="invLink">
				<Card>
					<Card.Img variant="top" src={data.titleImg} />
					<Card.Body>
						<Card.Subtitle>{renderDate(data.date, i18n.language )}</Card.Subtitle>
						<h3 className="mt-3">
							{data.title}
						</h3>
						<h5>
							{data.subtitle}
						</h5>
						<Card.Text as={"div"}>
							{data.preview}
						</Card.Text>
					</Card.Body>
				</Card>
			</Link>
			<Author author={data.author}/>
		</div>
	);
}

function SectionBlog(){
	const { t, i18n} = useTranslation();
	const article = getArticles(i18n.language)[0]; // most recent article
	return (
		<div className="sectionBlog" id="blog">
			<Container>
				<h2 className="text-center mb-3">{t("blogPrev.title")}</h2>
				<h4 className="text-center mb-5">{t("blogPrev.subtitle")}</h4>
				<div className="blogPrev">
					<ArticlePreview data={article}/>
					<Link to="/blog" className="invLink">
						<div className="next"><MdNavigateNext size={50}/></div>
					</Link>
				</div>
			</Container>
		</div>
	);
}
export {SectionBlog, ArticlePreview, Author, AuthorInline};