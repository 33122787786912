import React from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import './PageBlog.css';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {AuthorInline} from './SectionBlog';
import Share from './Share';

import {getArticleById} from "../articles";
import {renderDate} from "../util";

// Figures should not be wrapped in the container.narrow
// so end container before and restart after
function wrapContent(content){
	const elms = content.props.children;
	return elms.map((x, i) => x.type.displayName === "Figure"?
							<Container key={i} className="figure-container">{x}</Container>
						: <Container key={i}><div className="narrow">{x}</div></Container>);	
}


function PageBlog(){
	React.useEffect(() => {
    window.scrollTo(0, 0);
	});
	
	const {i18n} = useTranslation();
	const {id} = useParams();
	const article = getArticleById(id, i18n.language);
	if(!article) return <div>No article found!</div>;
	return (
		<div className="pageBlog">
			<Container className="narrow">
				<div className="blogTitle">
					<h1>{article.title}</h1>
					<h4>{article.subtitle}</h4>
				</div>
				<Row className="author-wp">
					<Col sm><AuthorInline author={article.author} date={renderDate(article.date, i18n.language)}/></Col>
				</Row>
			</Container>
			<Container>
				<Card.Img className="titleimg" src={article.titleImg} />
			</Container>
			<div className="content">
				{wrapContent(article.content)}
			</div>
			<div className="share-wp-wp">
				<Container>
					<div className="narrow">
						<Share title={article.title} subtitle={article.subtitle} url={"https://xorigin.tech/blog/" + article.id} />
					</div>
				</Container>
			</div>
		</div>
	);
}
export default PageBlog;