import React from 'react';
import Figure from 'react-bootstrap/Figure';
import './ImageFigure.css';

function ImageFigure({src, alt, children, credits}) {
	return (
		<Figure className="image-figure">
			<div className="image-wp">
				<Figure.Image
					alt={alt? alt : "Alt Text"}
					src={src}
				/>
				<div className="credits">
					{credits}
				</div>
			</div>
			<Figure.Caption>
				{children}
			</Figure.Caption>
		</Figure>
	);
}

export default ImageFigure;