import React from 'react';
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './SectionSolutions.css';

const sections = ["ai", "webDev", "automation", "prototype", "iot", "dataProcessing"];
const sectionsFileEndings = ["jpg", "jpg", "mp4", "png", "jpg", "mp4"];

function setDetailHeight(){
	const detailsHeight = document.querySelector(".details")?.clientHeight + 20;
	if(detailsHeight)
		document.querySelector(".sectionSolutions").style.setProperty("--detail-height", detailsHeight + "px");
}

function SolutionCard(props){
	const ref = React.createRef(null);

	function setDetailPos(){
		const correctPos = ref.current?.offsetTop + ref.current?.firstElementChild.offsetHeight;
		if(props.sel.ind === props.ind && correctPos && props.sel.top !== correctPos){
			props.setSelInd({	
					ind: props.ind,
					top: ref.current.offsetTop + ref.current.firstElementChild.offsetHeight});
		}
		[50, 200, 500, 800, 1200, 1800, 2300, 3000, 5000].forEach(t =>window.setTimeout(setDetailHeight, t));
	}

	React.useEffect(()=>{
		const resizeListener = () => {
      setDetailPos();
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);

    setDetailPos();
		// clean up function
    return () => {
      window.removeEventListener('resize', resizeListener);
    }
	});
	return (
		<Col ref={ref} className={(props.sel.ind === props.ind ?" active":"")+ (props.bottom?" bottom":"")}>
			<div className={"card-wp"}
						onClick={() => {props.setSelInd({	ind: props.ind,
								top: ref.current.offsetTop + ref.current.firstElementChild.offsetHeight});
								if(window.innerWidth < 992)
									window.setTimeout((elmt) => elmt.scrollIntoView({behavior: "smooth"}), 50, ref.current);}}>
				<div className="title">
					{props.children}
				</div>
				<Card>
					<Card.Img src={"img/" + props.img}/>
				</Card>
			</div>
		</Col>
	);
}

function Details({sel}){
	const detailsLinks= {
		ai: [],
		webDev: ["airtracker.xorigin.tech", "hifas.xorigin.tech"],
		automation: [],
		prototype: ["meginasat.org", "xclime.com", "airtracker.xorigin.tech"],
		iot: ["raintracker.xorigin.tech", "airtracker.xorigin.tech"],
		dataProcessing: ["xorigin.tech/blog/raintracker"],
	}

	const sectionName = sections[sel.ind];
	const { t } = useTranslation();
	const { pathname } = useLocation();

  React.useEffect(() => { //navigation changed
		window.setTimeout(setDetailHeight, 500);
  }, [pathname]);

	React.useEffect(()=>{
		window.addEventListener("load",() => setDetailHeight());
		return () => {
      window.removeEventListener('load', setDetailHeight);
    }
	},[]);

	function addHighlights(text){ // make <b>..</b> bold
		const regex = /<b>([^<])*<\/b>/g;
		const bs = text.match(regex);
		if(!bs) return <>{text}</>;
		const bJSX = bs.map(b => <b>{b.replace("<b>", "").replace("</b>", "")}</b>);
		const normText = text.split(regex);
		return <>
			{normText[0]}
			{bJSX.map((t, i) => <span key={i}>{t}{normText[(i+1)*2]}</span>)}
		</>
	}

	function sectionMedia(sectionName) {
		if (sectionName === "dataProcessing" || sectionName === "automation"){
			return (<video className="card-img" src={"img/solutions/solution_" + sectionName + "." + sectionsFileEndings[sections.indexOf(sectionName)]} type="video/mp4" autoPlay muted loop onLoad={()=>window.setTimeout(setDetailHeight, 100)}/>);
		} else {
			return (<img className="card-img" src={"img/solutions/solution_" + sectionName + "." + sectionsFileEndings[sections.indexOf(sectionName)]} alt="" onLoad={()=>window.setTimeout(setDetailHeight, 100)}/>);
		}
	}

	return (
		<div className="details-wp" style={{top: sel.top}}>
			<div className="details">
				<Row>
					<Col lg className="img-wp">
						<div>
							{sectionMedia(sectionName)}
						</div>
					</Col>
					<Col lg className="content d-flex flex-column">
						<h2 className="title">
							{t("solutions."+sectionName+".title")}
						</h2>
						<div>
							{addHighlights(t("solutions."+sectionName+".content"))}
						</div>
						<Row className="links">{
							detailsLinks[sectionName].map(l => 
								<Col key={l}><a href={"http://"+l}>{l}</a></Col>)
						}</Row>
					</Col>
				</Row>
			</div>
		</div>
	);
}



function SectionSolutions(){
	const [selInd, setSelInd] = React.useState({ind: 1, top: 0});
	const { t } = useTranslation();
	return (
		<div className="sectionSolutions" id="solutions">
			<Container>
				<Row>
					{
					sections.map((s, ind) =>
						<SolutionCard key={ind} ind={ind} sel={selInd}
								setSelInd={setSelInd} bottom={ind >=3}
								img={"SectionIcons-" + s + ".png"}>
									{t("solutions." + s + ".title")}
						</SolutionCard>
						)
					}
					<Details sel={selInd}/>
				</Row>
			</Container>
		</div>
	);
}
export default SectionSolutions;