import React from 'react';
import { useTranslation } from "react-i18next";
import './SectionBlogOverview.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {ArticlePreview as DefaultArticlePreview} from './SectionBlog';

import {getArticles} from "../articles";

function ArticlePreview({data}){
	return <DefaultArticlePreview className="article-preview " data={data}/>
}


function SectionBlogOverview(){
	React.useEffect(() => {
    window.scrollTo(0, 0);
	});
	const { t, i18n} = useTranslation();
	const articles = getArticles(i18n.language);
	return (
		<Container>
			<div className="sectionTitle">
				<h1>{t("blogOverview.title")}</h1>
				<h4>{t("blogOverview.subtitle")}</h4>
			</div>
			<Row>{
				articles.map((a, i) => <Col key={i}><ArticlePreview data={a}/> </Col>)
			}</Row>
		</Container>
	);
}
export default SectionBlogOverview;